import {ENV_TYPE} from 'src/types/Types';
const env = process.env.REACT_APP_ENV as ENV_TYPE;

const envConfigs: {
  [e in ENV_TYPE]: {
    reqTimeout: number;
    baseUrl: string;
  };
} = {
  local: {
    reqTimeout: 90000,
    baseUrl: 'http://localhost:3001'
  },
  development: {
    reqTimeout: 90000,
    baseUrl: 'https://nextupv2qa.quanteon.in'
  },
  qa: {
    reqTimeout: 90000,
    baseUrl: 'https://nextupv2qa.quanteon.in'
  },
  staging: {
    reqTimeout: 90000,
    baseUrl: 'https://stage.nextupperformance.com'
  },
  production: {
    reqTimeout: 90000,
    baseUrl: 'https://app.nextupperformance.com'
  }
};

const envConfig = envConfigs[env];
export const config = {
  REQ_TIME_OUT: envConfig.reqTimeout,
  BASE_URL: envConfig.baseUrl
};

export const baseEnvironment = {
  LOGIN: {
    STATE: 'state',
    REGION: 'region/findByState',
    SCHOOL_EXIST: 'school/isSchoolExist',
    USER: 'user',
    SCHOOL: 'school',
    FORGOT: 'forgot',
    IS_USER_EXIST: 'isUserExist'
  },
  ADMIN: {
    PENDING_CHALLENGES_COUNT: 'challenge/count/Pending',
    PENDING_CHALLENGES: 'challenge/findAll/Pending',
    APPROVE_REJECT_CHALLENGE: 'challenge/approveRejectProof',
    APPROVED_CHALLENGES: 'challenge/findAll/Approved',
    APPROVED_CHALLENGES_COUNT: 'challenge/count/Approved',
    SEARCH_PENDING: 'challenge/search/Pending',
    SEARCH_APPROVED: 'challenge/search/Approved',
    LEADERBOARD_SERVICE: 'leaderboard/findAll',
    LEADERBOARD_SEARCH: 'leaderboard/search',
    APPROVE_REJECT_LEADERBOARD: 'leaderboard/approveRejectProof',
    USERS: 'user',
    USER_SEARCH: 'user/search',
    UPDATE_USER_STATUS: 'user/updateUserStatus',
    SCHOOL: 'school',
    TOTAL_SCHOOLS: 'totalSchools',
    SEARCH: 'search',
    SEARCH_SCHOOL: 'searchSchool',
    UPDATE_SCHOOL_STATUS: 'updateSchoolStatus',
    REGION: 'region',
    COUNT_USERS: 'countUsers',
    DELETE_REGIONS: 'deleteRegion',
    EXERCISE: 'exercise',
    GET_SPORT_EXERCISE: 'getSportExercise',
    FIND_ALL_SPORTS: 'sport/findAll',
    SPORT_EXERCISES: 'sport_exercises',
    UPDATE_SPORT_EXERCISES: 'updateByExeAndSptId',
    SPORT: 'sport',
    EXERCISE_SERVICE: 'exercise',
    ORGANIZATION: 'organization',
    INVITE: 'invite-admin',
    USER: 'user',
    CURRENT: 'current',
    GET_ALL_ADMINS: 'getAllAdminsForAOrganization',
    INACTIVE: 'inactive',
    LEADERBOARD: 'leaderboard',
    FINDALL: 'findAll',
    SIGNUP: 'sign-up',
    ADMIN: 'admin',
    AUTH: 'auth'
  },
  SCHOOL_ADMIN: {
    CHALLENGE: 'challenge',
    COUNT: 'count',
    GET_ATHLETES_COUNT: 'getAthletesCount'
  },
  COACH: {
    ORGANIZATION: 'organization',
    INVITE: 'invite-coach',
    USER: 'user',
    CURRENT: 'current',
    GET_ALL_COACHES: 'getAllCoachesForAOrganization',
    INACTIVE: 'inactive',
    REQUESTED: 'requested',
    UPDATE_USER_STATUS: 'update_user_status',
    DELETE_USER: 'del_user',
    ACTIVATE_COACH: 'activate_user'
  },
  SPORTS: {
    LIST: 'user_sports'
  },
  CONTRACTS: {
    CONTRACT: 'contract',
    GET_EXTERNAL_LINKS: 'external_links',
    CONTRACT_SUBMISSION: 'contract_submission',
    CONTRACT_CHALLENGE: 'contract_challenge',
    APPROVE: 'approve',
    DECLINE: 'decline',
    ORG: 'org',
    NEXTUP: 'nextup',
    USERS: 'users',
    LIST: 'list',
    CONTRACT_INVITE: 'contract_invite',
    AWARD: 'award',
    CONTRACT_CONVERSATION: 'contract_conversation',
    DISMISS: 'dismiss',
    SUBMISSION: 'submission',
    CHALLENGE: 'challenge',
    FIND_ALL: 'findAll'
  },
  SUBMIT_CONTRACT: {
    CONTRACT_SUBMISSION: 'contract_submission'
  },
  USER: {
    USER: 'user',
    UPDATE: 'update',
    AUTH: 'auth',
    PASSWORD_RESET: 'password-reset'
  }
};
