import moment from 'moment';

export const phoneNumberFormatter = (e: {target: {value: string}}): string => {
  const onlyNums = e.target.value.replace(/[^0-9]/g, '');
  const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  return number;
};

export const phoneNumberStringFormatter = (e: string): string => {
  const onlyNums = e.replace(/[^0-9]/g, '');
  const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  return number;
};

export const emailDomain = (email: string): string => {
  const domain = email.substring(email.lastIndexOf('@') + 1);
  return domain;
};

export const calculateDuration = (duration: number): string => {
  const time = duration;
  const hours = Math.floor(time / 60);
  const seconds = Math.ceil(time - hours * 60);
  return hours + ':' + seconds;
};

export const quotientValue = (num: number, maxPageCount: number): number => {
  const quotient = Math.floor(num / maxPageCount);
  return quotient;
};

export const remainderValue = (num: number, maxPageCount: number): number => {
  const reminder = Math.floor(num % maxPageCount);
  return reminder;
};

export const uniqueId = (index: number): string => {
  return `id${index}` + new Date().getTime();
};

export const formatUserType = (userType: string): string => {
  let type = '';
  switch (userType) {
    case 'ATHLETIC_DIRECTOR':
      type = 'Director';
      break;
    case 'GENERAL_USER':
      type = 'General';
      break;
    case 'STUDENT_ATHLETE':
      type = 'Student';
      break;
    case 'COACH':
      type = 'Coach';
      break;
    case 'SCHOOL_ADMIN':
      type = 'Client';
      break;
    default:
      type = 'N/A';
  }
  return type;
};

export const formatDate = function (
  dateTime: string | number | Date | null | undefined
): string | Date {
  if (dateTime == undefined || dateTime == null) return '';
  const date = new Date(dateTime);
  const formattedDate = ('0' + date.getDate()).slice(-2);
  const formattedMonth = ('0' + (date.getMonth() + 1)).slice(-2);
  const formattedYear = date.getFullYear().toString();
  const dateString = formattedMonth + '/' + formattedDate + '/' + formattedYear;
  return dateString;
};

export const isDateValid = (
  dateTime: string | undefined | Date | null | number
): string => {
  if (dateTime == null) {
    return '';
  } else if (new Date() > new Date(dateTime)) {
    return 'No';
  } else {
    return 'Yes';
  }
};

export const datetoTimeZoneFormmater = (value: Date): string => {
  return new Date(value).toISOString();
};

export const removeDuplicateNumbers = (array: number[]): number[] => {
  return array.filter((item, index) => array.indexOf(item) === index);
};

export const truncateMaxValue = (
  maxValue: number,
  unit: string
): string | number => {
  if (unit == 'HOURS_AND_MINUTES') {
    if (maxValue == null || maxValue == undefined) {
      return 0 + ' hours ' + 0 + ' minutes';
    }
    const totalMinutes = maxValue / 60;
    const hours = parseInt((totalMinutes / 60).toString());
    const minutes = parseInt((totalMinutes % 60).toString());
    return hours + ' hours ' + minutes + ' minutes';
  } else if (unit == 'MINUTES_SECONDS') {
    if (maxValue == null || maxValue == undefined) {
      return 0 + ' minutes ' + 0 + ' seconds';
    }
    const minutes = parseInt((maxValue / 60).toString());
    const sec = maxValue - minutes * 60;
    return minutes + ' minutes ' + sec + ' seconds';
  }

  if (maxValue == undefined) return 0;
  else
    return (
      parseFloat(maxValue.toString()).toFixed(2) + ' ' + unit.toLowerCase()
    );
};

export const momentFormatter = (
  date: string | Date | null | undefined,
  formatType = 'MM/DD/YYYY'
): string => {
  return moment(date).format(formatType);
};

export const MultiplicationOfNumbersWithFactor = (
  number1: number,
  number2: number,
  factor: number
): string => {
  const number = ((number1 + number2) * factor * 0.01).toFixed(2);
  return `${number}`;
};

export const currencyFormatter = (amount: number | string): string => {
  return '$' + Number(amount).toFixed(2);
};

export const zeroCurrencyValue = '$0.00';

export const base64STRINGPRECEDINGTEXT = 'data:image/jpeg;base64';
export const HEIC = '.heic';

export const isValidUserHandle = (input: string | null | undefined): boolean | null  => {
  const regex = /^[A-Za-z0-9._+'']+$/;

  if(input && input?.length > 0) {
    return regex.test(input);
  }
  else {
    return null
  }
}
